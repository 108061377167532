<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                Registered Home Care Aide Training Log
                <br>
                <small>HCS 500 Training Log 2018</small>
              </b-card-title>

              <p><b>INSTRUCTIONS:</b> This form is intended to provide Home Care Organizations with method to maintain a training verification Jog for each Affiliated Home Care Aide. Although maintenance of a Training Log is required per section 90-067(c)(1) of the Written Directives, the use of this specific form is not required. Home Care Organizations have flexibility to document training requirements that best fit their business needs.</p>
              <p><b>HOME CARE ORGANIZATION REQUIREMENTS:</b> The Home Care Organization licensee must maintain a verification log of training for each affiliated Home Care Aide which includes the information listed in 90-067(c)(1) of the Written Directives. Documentation must be kept in personnel file for Department review.</p>

              <!-- Spacer -->
              <hr>

              <!-- Form -->
              <validation-observer ref="simpleRules">
                <b-form>

                  <input
                    v-model="form.id"
                    type="hidden"
                  >

                  <input
                    v-model="form.cid"
                    type="hidden"
                  >

                  <b-row>

                    <!-- Input Last Name -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Last Name"
                        label-for="last-name"
                      >
                        <label
                          class="sr-only"
                          for="last-name"
                        >
                          * Last Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Last Name"
                          vid="last-name"
                          rules="required"
                        >
                          <b-form-input
                            id="last-name"
                            ref="lastName"
                            v-model="basicInformation[0].lastName"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Last Name"
                            title="Last Name"
                            readonly
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input First Name -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* First Name"
                        label-for="first-name"
                      >
                        <label
                          class="sr-only"
                          for="first-name"
                        >
                          * First Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="First Name"
                          vid="first-name"
                          rules="required"
                        >
                          <b-form-input
                            id="first-name"
                            ref="firstName"
                            v-model="basicInformation[0].firstName"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="First Name"
                            title="First Name"
                            readonly
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Personnel ID -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Personnel ID (Optional)"
                        label-for="personnel-id"
                      >
                        <label
                          class="sr-only"
                          for="personnel-id"
                        >
                          Personnel ID (Optional)
                        </label>
                        <b-form-input
                          id="personnel-id"
                          ref="personnelId"
                          v-model="basicInformation[0].personnelId"
                          type="text"
                          placeholder="Personnel ID"
                          title="Personnel ID"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <!-- Input Position -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Position"
                        label-for="position"
                      >
                        <label
                          class="sr-only"
                          for="position"
                        >
                          * Position
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Position"
                          vid="position"
                          rules="required"
                        >
                          <b-form-input
                            id="position"
                            ref="position"
                            v-model="basicInformation[0].position"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Position"
                            title="Position"
                            readonly
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Hire Date -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Hire Date"
                        vid="hire-date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Hire Date"
                          label-for="hire-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="hire-date"
                          >
                            * Hire Date
                          </label>
                          <b-form-input
                            id="hire-date"
                            ref="hireDate"
                            v-model="basicInformation[0].hireDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Hire Date"
                            readonly
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <!-- Input Registration Date -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Registration Date"
                        vid="registration-date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Registration Date"
                          label-for="registration-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="registration-date"
                          >
                            * Registration Date
                          </label>
                          <flat-pickr
                            id="registration-date"
                            ref="registrationDate"
                            v-model="basicInformation[0].registrationDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Registration Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <!-- Table -->
                  <b-table
                    ref="trainingLog"
                    :fields="fields"
                    :items="trainingLog"
                    responsive
                    title="Training Log"
                  >
                    <!-- Column dateCompleted -->
                    <template #cell(dateCompleted)="data">
                      <b-form-group class="width-268px">
                        <b-input-group class="flatpickr">
                          <flat-pickr
                            v-model="data.item.dateCompleted"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-dark"
                              title="Clear"
                              class="brdr-gray px-1"
                              data-clear
                            >
                              <i class="fa fa-times">
                                <span
                                  aria-hidden="true"
                                  class="sr-only"
                                >
                                  Clear
                                </span>
                              </i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </template>

                    <!-- Column title -->
                    <template #cell(title)="data">
                      <b-form-group class="width-268px">
                        <b-form-input
                          v-model="data.item.title"
                          type="text"
                          placeholder="Training Title"
                        />
                      </b-form-group>
                    </template>

                    <!-- Column description -->
                    <template #cell(description)="data">
                      <b-form-group class="width-268px">
                        <b-form-textarea
                          v-model="data.item.description"
                          placeholder="Brief Description of Topics Covered"
                          rows="4"
                        />
                      </b-form-group>
                    </template>

                    <!-- Column organization -->
                    <template #cell(organization)="data">
                      <b-form-group class="width-268px">
                        <b-form-input
                          v-model="data.item.organization"
                          type="text"
                          placeholder="Training Organization Name"
                        />
                      </b-form-group>
                    </template>

                    <!-- Header instructor -->
                    <template #head(instructor)>
                      <span>Instructor<br>First Name & Last Name<br>(If in-person training)</span>
                    </template>

                    <!-- Column instructor -->
                    <template #cell(instructor)="data">
                      <b-form-group class="width-268px">
                        <b-form-input
                          v-model="data.item.instructor"
                          type="text"
                          placeholder="Instructor"
                        />
                      </b-form-group>
                    </template>

                    <!-- Header location -->
                    <template #head(location)>
                      <span>Location of Training<br>(If online, specify website)</span>
                    </template>

                    <!-- Column location -->
                    <template #cell(location)="data">
                      <b-form-group class="width-268px">
                        <b-form-textarea
                          v-model="data.item.location"
                          placeholder="Location of Training"
                          rows="4"
                        />
                      </b-form-group>
                    </template>

                    <!-- Header trainingHour -->
                    <template #head(trainingHour)>
                      <span>Training Hour Requirements<br>(Enter hours in applicable column)</span>
                      <b-row
                        class="border-top mt-1 pt-1"
                      >
                        <b-col>
                          Entry Level
                        </b-col>

                        <b-col>
                          Annual
                        </b-col>
                      </b-row>
                    </template>

                    <!-- Column trainingHour -->
                    <template #cell(trainingHour)="data">

                      <b-row class="width-296px">

                        <b-col>
                          <b-form-group class="width-120px">
                            <b-form-input
                              id="entry-level"
                              v-model="data.item.entryLevel"
                              type="number"
                              placeholder="Entry Level"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col>
                          <b-form-group class="width-120px">
                            <b-form-input
                              id="annual"
                              v-model="data.item.annual"
                              type="number"
                              placeholder="Annual"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                    </template>
                  </b-table>
                  <validation-provider
                    #default="{ errors }"
                    name="Signature"
                    rules="required"
                  >
                    <b-form-group
                      label="* Signature of Employee"
                      label-for="pt-signature"
                    >
                      <label
                        class="sr-only"
                        for="pt-signature"
                      >
                        * Signature of Employee
                      </label>
                      <vue-signature-pad
                        ref="signaturePad"
                        v-model="basicInformation[0].signature"
                        :state="errors.length > 0 ? false : null"
                        width="100%"
                        height="150px"
                        :options="{ onBegin, onEnd }"
                        class="form-control"
                        :class="{ 'is-invalid' : errors.length > 0 && basicInformation[0].signature === ''}"
                        title="Signature"
                      />

                      <b-row>

                        <b-col>
                          <small
                            v-if="basicInformation[0].signature === ''"
                            class="text-danger"
                          >{{ errors[0] }}
                          </small>
                        </b-col>

                        <b-col
                          class="text-right mt-1"
                        >
                          <!-- Button Undo -->
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-dark"
                            class="mr-1"
                            @click="undoSignature"
                          >
                            Undo
                          </b-button>

                          <!-- Button Clear -->
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-danger"
                            @click="clearSignature"
                          >
                            Clear
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </validation-provider>
                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr17/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click="validationForm()"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form16', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form17b', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BFormTextarea, BTable, BButton, VBToggle,
} from 'bootstrap-vue'
import { VueSignaturePad } from 'vue-signature-pad'
import Swal from 'sweetalert2'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormTextarea,
    BTable,
    BButton,
    VueSignaturePad,

    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr17'

    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })

    const lastName = ref('')
    const firstName = ref('')
    const position = ref('')
    const hireDate = ref('')

    if (router.currentRoute.query.id) {
      // console.log(router.currentRoute.query.id)
    }

    const form = ref({})

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    const userData = ref(getUserData())

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]
    const flatPickrConfig = {
      wrap: true, // set wrap to true only when using 'input-group'
      // altFormat: 'm/d/Y',
      // altInput: true,
      dateFormat: 'Y-m-d',
    }
    const vueSignaturePadOptions = {
      penColor: 'rgb(0, 0, 0)',
    }

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      lastName,
      firstName,
      position,
      hireDate,

      form,

      formUpdate,
      formUpdateStatus,

      formCopy,

      userData,

      formsOptions,

      flatPickrConfig,
      vueSignaturePadOptions,

      sock,
      sockId,
    }
  },
  data() {
    return {
      action: this.$route.params.action,
      id: this.$route.query.id,
      isNotSaved: false,
      forms: this.$route.name,
      formUpdateChanges: [],
      basicInformation: [
        {
          lastName: '',
          firstName: '',
          position: '',
          hireDate: '',
          signature: '',
          registrationDate: '',
          personnelId: '',
        },
      ],
      fields: [
        { key: 'dateCompleted', label: 'Date Training Completed', thClass: 'text-center align-middle' },
        { key: 'title', label: 'Training Title', thClass: 'text-center align-middle' },
        { key: 'description', label: 'Brief Description of Topics Covered', thClass: 'text-center align-middle' },
        { key: 'organization', label: 'Training Organization Name', thClass: 'text-center align-middle' },
        { key: 'instructor', thClass: 'text-center align-middle' },
        { key: 'location', thClass: 'text-center align-middle' },
        { key: 'trainingHour', thClass: 'text-center align-middle' },
      ],
      trainingLog: [
        {
          dateCompleted: '',
          title: 'New Hire Orientation',
          description: '2-Hour Company overview',
          organization: 'Circle of Care, LLC',
          instructor: 'Cheri Platte',
          location: 'Tustin Office',
          entryLevel: 2,
          annual: '',
        },
        {
          dateCompleted: '',
          title: 'New Hire Orientation',
          description: '3-Hour Basic Training\n-Basic Safety Precautions\n-Emergency Procedures\n-Infection Control',
          organization: 'Circle of Care, LLC',
          instructor: 'Ava Noravong',
          location: 'Tustin Office',
          entryLevel: 3,
          annual: '',
        },
        {
          dateCompleted: '',
          title: '(1) BODY MECHANICS',
          description: 'Video + Reading Materials: Proper Posture, Principles of Body Mechanics/Proper Body Body Mechanics',
          organization: 'Institute for Professional Care Education',
          instructor: '',
          location: 'https://www.plex.tv/\n\nhttps://coc.lingenterprises.com/Timelocks',
          entryLevel: '',
          annual: 1,
        },
        {
          dateCompleted: '',
          title: '(2) ELDER ABUSE',
          description: 'Video + Reading Materials: Definition/Factors of Abuse, Signs & Symptoms of Abuse, & Prevention',
          organization: 'Institute for Professional Care Education',
          instructor: '',
          location: 'https://www.plex.tv/\n\nhttps://coc.lingenterprises.com/Timelocks',
          entryLevel: '',
          annual: 1,
        },
        {
          dateCompleted: '',
          title: '(3) ETHICAL & LEGAL BEHAVIOR',
          description: 'Video + Reading Materials:Legal Understanding, Responsibilities & Liabilities',
          organization: 'Institute for Professional Care Education',
          instructor: '',
          location: 'https://www.plex.tv/\n\nhttps://coc.lingenterprises.com/Timelocks',
          entryLevel: '',
          annual: 1,
        },
        {
          dateCompleted: '',
          title: '(4) FALL PREVENTION',
          description: 'Video + Reading Materials: Falls, Prevention for Older Adults/Caregiver, & Gait Belt',
          organization: 'Institute for Professional Care Education',
          instructor: '',
          location: 'https://www.plex.tv/\n\nhttps://coc.lingenterprises.com/Timelocks',
          entryLevel: '',
          annual: 1,
        },
        {
          dateCompleted: '',
          title: '(5) FIRE SAFETY',
          description: 'Video + Reading Materials: Understanding Factors of Fire Dangers, Fire Hazards, & Personal Safety',
          organization: 'Institute for Professional Care Education',
          instructor: '',
          location: 'https://www.plex.tv/\n\nhttps://coc.lingenterprises.com/Timelocks',
          entryLevel: '',
          annual: 1,
        },
        {
          dateCompleted: '',
          title: '(6) OBSERVATION & DOCUMENTATION',
          description: 'Video + Reading Materials: Develop Good Observation Skills, Recognize Good Documentation, & Proper Document Procedures',
          organization: 'Institute for Professional Care Education',
          instructor: '',
          location: 'https://www.plex.tv/\n\nhttps://coc.lingenterprises.com/Timelocks',
          entryLevel: '',
          annual: 1,
        },
      ],
      required,
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        // if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
        if (key === 'basicInformation') {
          if (JSON.parse(val)[0].signature !== this.formCopy[key][0].signature) {
            this.formUpdateChanges.push(this.$refs.signaturePad.$attrs.title)
          }
          if (JSON.parse(val)[0].personnelId !== this.formCopy[key][0].personnelId) {
            this.formUpdateChanges.push(this.$refs.personnelId.$attrs.title)
          }
          if (JSON.parse(val)[0].registrationDate !== this.formCopy[key][0].registrationDate) {
            this.formUpdateChanges.push(this.$refs.registrationDate.$attrs.title)
          }
        } else {
          let valCopy = this.formCopy[key]
          if (Array.isArray(this.formCopy[key])) {
            valCopy = JSON.stringify(this.formCopy[key])
          }
          // console.log(key, val, valCopy, val !== valCopy)
          if (val !== valCopy) {
            try {
              this.formUpdateChanges.push(this.$refs[key].$attrs.title)
            } catch (error) {
              console.error(error)
            }
          }
        }
      })
    },
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }

    if (this.$route.params.action === 'edit' && this.$route.query.id != null) {
      this.fetchCaregiverData()
      this.fetchForm17a()
    }
    if (this.$route.params.action === 'add' && this.$route.query.id !== null) {
      this.fetchCaregiverData()
      this.fetchForm17a()
    }
    if (this.$route.params.action === 'add') {
      this.fetchCaregiverData()
    }
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr17/requestFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr17/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          this.basicInformation = JSON.parse(jsonObjectLiterals.data.basicInformation)
          this.trainingLog = JSON.parse(jsonObjectLiterals.data.trainingLog)

          this.basicInformation[0].signature = JSON.parse(jsonObjectLiterals.data.basicInformation)[0].signature
          this.$refs.signaturePad.clearSignature()
          this.$refs.signaturePad.fromDataURL(JSON.parse(jsonObjectLiterals.data.basicInformation)[0].signature)

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr17/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr17/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    /* saveForm() {
      if (this.action === 'add') {
        const formData = {
          id: this.id,
          basicInformation: this.basicInformation,
          trainingLog: this.trainingLog,
        }
        store
          .dispatch('app-hr17/addForm17a', formData)
          .then(response => {
            console.log(response)
            Swal.fire({
              icon: 'success',
              title: 'Saved!',
              text: 'Saved successfully.',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch(error => {
            console.error(error)

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              showConfirmButton: false,
              timer: 1500,
            })
          })
      } else if (this.action === 'edit') {
        const formData = {
          id: this.id,
          basicInformation: this.basicInformation,
          trainingLog: this.trainingLog,
        }
        store
          .dispatch('app-hr17/updateForm17a', { formData })
          .then(response => {
            console.log(response)
            Swal.fire({
              icon: 'success',
              title: 'Saved!',
              text: 'Saved successfully.',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          .catch(error => {
            console.error(error)

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              showConfirmButton: false,
              timer: 1500,
            })
          })
      }
    }, */
    validationForm() {
      const self = this

      /* const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      this.basicInformation[0].signature = !isEmpty ? data : '' */
      this.$refs.simpleRules.validate().then(success => {
      // this.saveForm()
        if (success) {
          const formData = {
            cid: this.id,
            id: this.form.id,
            basicInformation: this.basicInformation,
            trainingLog: this.trainingLog,
          }

          this.form.basicInformation = this.basicInformation
          this.form.trainingLog = this.trainingLog

          // console.log(JSON.stringify(this.form), JSON.stringify(this.formCopy), JSON.stringify(this.form) !== JSON.stringify(this.formCopy))
          let msg = 'Do you want to save the changes?'
          if (this.action === 'add') msg = 'Do you want to add these data?'
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: msg,
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.isNotSaved = true
              store
                .dispatch(this.action === 'add' ? 'app-hr17/addForm17a' : 'app-hr17/updateForm17a', formData)
                .then(response => {
                  // eslint-disable-next-line
                  const jsonObjectLiterals = response.data.response.data

                  this.form.id = jsonObjectLiterals.id

                  /* if (this.userData.role === 'agent' && this.action === 'edit' && (JSON.stringify(this.form.trainingLog) !== JSON.stringify(this.formCopy.trainingLog) || JSON.stringify(this.form.basicInformation[0].signature) !== JSON.stringify(this.formCopy.basicInformation[0].signature) || JSON.stringify(this.form.basicInformation[0].personnelId) !== JSON.stringify(this.formCopy.basicInformation[0].personnelId) || JSON.stringify(this.form.basicInformation[0].registrationDate) !== JSON.stringify(this.formCopy.basicInformation[0].registrationDate))) {
                    self.requestFormUpdate()
                  } */

                  if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                    self.requestFormUpdate()
                  }

                  Swal.fire({
                    icon: 'success',
                    title: 'Saved!',
                    text: 'Saved successfully.',
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                })
                .catch(error => {
                  console.error(error)

                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .finally(() => {
                  self.isNotSaved = false
                })
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
    fetchForm17a() {
      store
        .dispatch('app-hr17/fetchForm17a', { id: this.id })
        .then(response => {
          // eslint-disable-next-line
          const data = response.data.response.data[0]
          const obj = JSON.parse(data.forms)

          // console.log(obj)
          this.basicInformation = obj.basicInformation
          this.trainingLog = obj.trainingLog

          this.basicInformation[0].signature = obj.basicInformation[0].signature
          this.$refs.signaturePad.clearSignature()
          this.$refs.signaturePad.fromDataURL(obj.basicInformation[0].signature)

          this.form = obj

          this.form.id = JSON.stringify(data.id)

          this.form.cid = JSON.stringify(data.cid)

          if (this.form.cid) {
            this.form.form = 'folder4_form4a'

            this.formCopy = JSON.parse(JSON.stringify(this.form))

            store.dispatch('app-hr17/fetchFormUpdate', { form: this.form.form, form_id: this.form.cid })
              .then(response2 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response2.data.response.data

                // console.log(jsonObjectLiterals.data)

                if (jsonObjectLiterals.data.status) {
                  this.formUpdate = jsonObjectLiterals.data

                  this.formUpdateStatus = this.formUpdate.status
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature()
    },
    undoSignature() {
      this.$refs.signaturePad.undoSignature()
    },
    onBegin() {
      // Do something
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      this.basicInformation[0].signature = !isEmpty ? data : ''
    },
    fetchCaregiverData() {
      store
        .dispatch('app-hr17/fetchCaregiverData', { id: this.id })
        .then(response => {
          // eslint-disable-next-line
          const obj = response.data.response.data[0]
          // console.log(this.basicInformation[0])
          this.basicInformation[0].firstName = obj.firstname
          this.basicInformation[0].lastName = obj.lastname
          this.basicInformation[0].position = obj.position
          this.basicInformation[0].hireDate = obj.hiredate
        })
        .catch(error => {
          console.error(error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
.width-296px
{
  width: 296px;
}

.width-268px
{
  width: 268px;
}

.width-120px
{
  width: 120px;
}

.table th, .table td {
  padding: 0.36rem 1rem;
}
</style>

<style scoped>
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
